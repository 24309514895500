export const MainSpeakersRu = [
  {
    image : '/public/img/cont/speakers/christian_barra.jpg',
    name : 'Christian Barra',
    position : 'Senior Backend Developer',
    company : 'Infarm',
    place : 'Berlin, Germany',
    rept : [
      {
        title : 'Not just a Developer [En]',
        description :
          '<p>Did anyone ever tell you that you should not do something? Because you are not someone? No worries, ' +
          'it happens to everyone. Once I was told: "we\'re looking for a developer, and you don\'t look like one".' +
          ' It might hurt, but at the end it is actually useful for you. </p>' +
          '<p>During this talk I will tell you a story of breaking the boundaries of a traditional developer stereotype:' +
          ' how can one wander the world with a single backpack, organize events for thousands of people, run a business' +
          ' and yet still write code.</p>'
      }
    ],
    aboutSpeaker : '',
    socialsRendered : '',
    socials : [
      {
        link : 'https://twitter.com/christianbarra',
        fatype : 'twitter'
      },
      {
        link : 'https://www.linkedin.com/in/christianbarra/',
        fatype : 'linkedin'
      },
      {
        link : 'https://www.chrisbarra.xyz/',
        fatype : 'link'
      }
    ]
  },
  {
    image : '/public/img/cont/speakers/jorge_torres.jpg',
    name : 'Jorge Torres',
    position : 'Co-founder & CTO',
    company : 'MindsDB',
    place : 'Berkeley, California, USA',
    rept : [
      {
        title : 'Machine Learning Democratization with Python [En]',
        description :
          '<p>This talk is divided in three sections: The first one, dives into the ' +
          'importance of democratizing Machine Learning, its objective is to demonstrate' +
          ' why now this is a crucial issue to be solved and to show the risks and problems' +
          ' that present themselves when Machine Learning Engineers take in the responsibility ' +
          'of building predictive technologies in domains that are not necessarily where they ' +
          'are the most experts on.</p>' +
          '<p>The second part of this talk, focuses on some solutions and approaches to challenges ' +
          'of democratizing Machine Learning with Python, as well as the journey and results ' +
          'we have seen while building the open source Python project MindsDB as well as what' +
          ' would be new ways that the ML community can think of the next generation of tools ' +
          'being built.</p>' +
          '<p>The third section of this talk, focuses on the new issues that are born once ' +
          'machine Learning capabilities are on the hands of not ML experts, more importantly ' +
          'the implications of delegating the Machine Learning machinery to a Python toolset,' +
          ' and what is important in order to trust those systems. Which leads to the importance ' +
          'in further developing tools in explainability, interpretability and robustness of ML.</p>'
      }
    ],
    aboutSpeaker : '',
    socialsRendered : '',
    slideshare : '/public/docs/slideshare/jorge_torres_-_machine_learning_democratization_with_python.pdf',
    slideshareType : 'pdf',
    socials : [
      {
        link : 'https://twitter.com/mindsdb',
        fatype : 'twitter'
      },
      {
        link : 'https://github.com/mindsdb/mindsdb',
        fatype : 'github'
      },
      {
        link : 'https://www.linkedin.com/in/torresjorge/',
        fatype : 'linkedin'
      }
    ]
  },
  {
    image : '/public/img/cont/speakers/gabriel_bianconi.jpg',
    name : 'Gabriel Bianconi',
    position : 'Founder',
    company : 'Scalar Research',
    place : 'New York, United States',
    rept : [
      {
        title : 'Introduction to Face Processing with Computer Vision [En]',
        description :
          '<p>Ever wonder how Facebook’s facial recognition or Snapchat’s filters work?</p>' +
          '<p>Faces are a fundamental piece of photography, and building applications around them has never been easier' +
          ' with open-source libraries and pre-trained models.</p>' +
          '<p>In this talk, we’ll help you understand some of the computer vision and machine learning techniques behind ' +
          'these applications. Then, we’ll use this knowledge to develop our own prototypes to tackle tasks such as face ' +
          'detection (e.g. digital cameras), recognition (e.g. Facebook Photos), classification (e.g. identifying emotions),' +
          ' manipulation (e.g. Snapchat filters), and more.\n' +
          '</p>'
      }
    ],
    aboutSpeaker : '',
    socialsRendered : '',
    socials : [
      {
        link : 'https://twitter.com/GabrielBianconi',
        fatype : 'twitter'
      },
      {
        link : 'http://github.com/GabrielBianconi',
        fatype : 'github'
      },
      {
        link : 'https://gabrielbianconi.com/',
        fatype : 'link'
      }
    ]
  },
  {
    image : '/public/img/cont/speakers/emanuil_tolev.jpg',
    name : 'Emanuil Tolev',
    position : 'Community Engineer',
    company : 'Elastic',
    place : 'London, UK',
    rept : [
      {
        title : 'Advanced, free, open-source application performance monitoring for your Python web apps [En]',
        description : '<p>In this talk and technical demo we\'re going to walk through a minimal example Django web app,' +
          ' simulate traffic of varied intensity, and monitor the results with Elastic Application Performance ' +
          'Monitoring, an open source toolkit designed to help you fix mid-night production alerts sooner and go' +
          ' back to bed.</p>\n' +
          '<p>It is 3am. Your phone rings with a special tone - the systems propping up your employer\'s or client\'s ' +
          'website are down. You open your eyes and groan, fumbling for your laptop. You\'re used to the system\'s worst' +
          ' problems, you have an idea what it might be. It\'s slow going while you manually check if every component ' +
          'is working. You go back to bed in an hour, having written up a few of the most important details and brought ' +
          'the website back up. Hooray!</p>\n' +
          '<p>However, at 6am you get another call. You sigh and fix it. Then another at 7am, and at 11am. A few days ' +
          'later the outages repeat. What is going on?!</p>\n' +
          '<p>There are types of problems that can\'t be solved purely through experience and knowledge of a system. ' +
          'You need deeper monitoring data than "% CPU" and "RAM free" to resolve them. Worse still, there are multiple' +
          ' components involved with failures in one masking a true underlying cause in another, like a slow loading ' +
          'page masking a slow database, which in turn makes you wonder what queries it\'s really answering.</p>'
      }
    ],
    aboutSpeaker : '',
    socialsRendered : '',
    socials : [
      {
        link : 'https://twitter.com/emanuil_tolev/',
        fatype : 'twitter'
      },
      {
        link : 'https://github.com/emanuil-tolev/',
        fatype : 'github'
      },
      {
        link : 'https://www.linkedin.com/in/emanuil-tolev/',
        fatype : 'linkedin'
      }
    ]
  },
  {
    image : '/public/img/cont/speakers/vsevolod_solovyov.jpg',
    name : 'Всеволод Соловьёв',
    position : 'Co-founder and CTO',
    company : 'Prophy Science',
    place : 'Киев, Украина',
    rept : [
      {
        title : 'Data science з печі до столу [Ru]',
        description : 'У світі вже написано більше ніж 100 мільйонів наукових' +
          ' статей й темп публікацій все пришвидшується. Більш ніж один мільйон' +
          ' з цих статей написаний якимось Вангом. Робота з результатами наукової' +
          ' діяльності може бути досить далекою від самої наукової діяльності. ' +
          'Завітайте на цю доповідь, щоб почути різні історії щодо обробки та ' +
          'визначення суті цих даних.'
      }
    ],
    aboutSpeaker : '',
    socialsRendered : '',
    slideshare : '/public/docs/slideshare/vsevolod_solovyov_-_data_science_from_the_trenches.pdf',
    slideshareType : 'pdf',
    socials : [
      {
        link : 'https://twitter.com/murkt',
        fatype : 'twitter'
      },
      {
        link : 'https://www.linkedin.com/in/vsolovyov/',
        fatype : 'linkedin'
      },
      {
        link : 'https://www.facebook.com/vsevolod.solovyov',
        fatype : 'facebook'
      }
    ]
  },
  {
    image : '/public/img/cont/speakers/elena_shylko.jpg',
    name : 'Елена Шилко',
    position : 'Backend Developer',
    company : 'Gurtam',
    place : 'Минск, Беларусь',
    rept : [
      {
        title : 'MQTT - прямая дорога в Интернет вещей [Ru]',
        description : '<p>MQTT is a publish/subscribe messaging transport protocol. It is open, light-weight and simple.' +
          ' These characteristics make it ideal for use for communication in Machine to Machine (M2M) and Internet of ' +
          'Things (IoT) contexts. Python is also extremely popular in IoT world. So we have a great Python + MQTT combo.)</p>' +
          '<p>In this talk I will introduce MQTT protocol and its main features. I’ll discuss what’s new in latest' +
          ' MQTT 5.0 version and why is it cool. I will also show some interesting usage examples, our experience and' +
          ' compare main brokers and clients implementations available right now (mainly concentrating on those' +
          ' implemented in Python of course)</p>'
      }
    ],
    aboutSpeaker : '',
    socialsRendered : '',
    socials : [
      {
        link : 'https://twitter.com/leenka42 https://github.com/Lenka42',
        fatype : 'twitter'
      },
      {
        link : 'https://www.facebook.com/lenka42',
        fatype : 'facebook'
      }
    ]
  },
  {
    image : '/public/img/cont/speakers/alexey_borisenko.jpg',
    name : 'Алексей Борисенко',
    position : 'DevNet developer',
    company : 'Cisco',
    place : 'Киев, Украина',
    rept : [
      {
        title : 'Сетевая программируемость с использованием Python [Ua]',
        description : '<p>Во время доклада будет рассмотрена сфера Network programmability (развитие, возможности, задачи). Мы рассмотрим:</p>\n' +
          '<ul>\n' +
          '  <li>разработка приложений для инфраструктуры</li>\n' +
          '  <li>новейшие тенденции, такие как software-defined networking</li>\n' +
          '  <li>библиотеки и решения для Network programmability</li>\n' +
          '  <li>задачи которые можно решить с помощью Python</li>\n' +
          '</ul>'
      }
    ],
    aboutSpeaker : '',
    socialsRendered : '',
    slideshare : '/public/docs/slideshare/alexey_borisenko_-_network_programmability_using_python.pdf',
    slideshareType : 'pdf',
    socials : [
      {
        link : 'https://twitter.com/alex_dev_k',
        fatype : 'twitter'
      },
      {
        link : 'https://github.com/oborys',
        fatype : 'github'
      },
      {
        link : 'https://developer.cisco.com/',
        fatype : 'link'
      }
    ]
  },
  {
    image : '/public/img/cont/speakers/mikhail_faraponov.jpeg',
    name : 'Михаил Фарапонов',
    position : 'Software Engineer',
    company : 'Lohika',
    place : 'Одесса, Украина',
    rept : [
      {
        title : 'Overview of Kubernetes Metacontrollers with Python [Ru]',
        description :
          '<p>В этом докладе я расскажу как с помощью CRD и аддонов для K8s можно воплотить в жизнь все ваши идеи ' +
          'по автоматизации обслуживания stateful приложений</p>'
      }
    ],
    aboutSpeaker : '',
    socialsRendered : '',
    slideshare : '/public/docs/slideshare/mikhail_faraponov_-_overview_of_kubernetes_metacontrollers_with_python.pptx',
    socials : [
      {
        link : 'https://twitter.com/pentestable',
        fatype : 'twitter'
      },
      {
        link : 'https://www.linkedin.com/feed/https://www.linkedin.com/in/mikefaraponov/',
        fatype : 'linkedin'
      },
      {
        link : 'https://github.com/mikefaraponov',
        fatype : 'link'
      }
    ]
  },
  {
    image : '/public/img/cont/speakers/anton_caceres.jpg',
    name : 'Anton Caceres',
    position : 'CEO',
    company : 'TECH-5',
    place : 'Мюнхен, Германия',
    rept : [
      {
        title : 'The epic zoo of microservices [UA]',
        description : ''
      }
    ],
    aboutSpeaker : '',
    socialsRendered : '',
    socials : [
      {
        link : 'http://github.com/ma3str0',
        fatype : 'github'
      },
      {
        link : 'https://twitter.com/anton_caceres',
        fatype : 'twitter'
      },
      {
        link : 'https://caceres.me',
        fatype : 'link'
      }
    ]
  },
  {
    image : '/public/img/cont/speakers/taras_matsyk.jpg',
    name : 'Тарас Мацик',
    position : 'Sr. Software Engineer',
    company : 'Lalafo',
    place : 'Киев, Украина',
    rept : [
      {
        title : 'Event Driven ML [UA]',
        description : 'Як загорнути розпізнавання картинок в розширюваний SaaS з Go, Python та розподілених обчислень.'
      }
    ],
    aboutSpeaker : '',
    socialsRendered : '',
    slideshare : '/public/docs/slideshare/taras_matsyk_-_event_driven_ml.pdf',
    slideshareType : 'pdf',
    socials : [
      {
        link : 'https://github.com/tarasmatsyk',
        fatype : 'github'
      },
      {
        link : 'https://twitter.com/tarasmatsyk/',
        fatype : 'twitter'
      },
      {
        link : 'https://tarasmatsyk.com',
        fatype : 'link'
      }
    ]
  }
];
