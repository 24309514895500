export const MainSchedule2020Ru = [
    {
        time : {
            start : '07:30',
            end : ''
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #1 на локацию</a>'}
        ]
    },
    {
        time : {
            start : '08:00',
            end : ''
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #2 на локацию</a>'}
        ]
    },
    {
        time : {
            start : '08:30',
            end : ''
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #3 на локацию</a>'}
        ]
    },
    {
        time : {
            start : '08:30',
            end : '10:00'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Регистрация/кофе и круассаны'}
        ]
    },
    {
        time : {
            start : '10:00',
            end : '10:25'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Laysa Uchoa', title : '31 days of Python 3.10 [En]'}
        ]
    },
    {
        time : {
            start : '10:25',
            end : '10:35'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Перерыв'}
        ]
    },
    {
        time : {
            start : '10:35',
            end : '11:20'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Всеволод Соловьёв', title : 'Моя найбільша архітектурна помилка [Ua]'}
        ]
    },
    {
        time : {
            start : '11:20',
            end : '11:30'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Перерыв'}
        ]
    },
    {
        time : {
            start : '11:30',
            end : '12:15'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Андрей Солдатенко', title : 'Дебаггинг асинхронных приложений в Python [Ru]'}
        ]
    },
    {
        time : {
            start : '12:15',
            end : '12:25'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Перерыв'}
        ]
    },
    {
        time : {
            start : '12:25',
            end : '13:10'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Секретный спикер', title : ''}
        ]
    },
    {
        time : {
            start : '13:10',
            end : '13:50'
        },
        icon : 'cutlery', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Обед (часть I)'}
        ]
    },
    {
        time : {
            start : '13:50',
            end : '14:30'
        },
        icon : 'cutlery', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Обед (часть II)'}
        ]
    },
    {
        time : {
            start : '14:30',
            end : '15:05'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Anton Kartashov', title : 'Streamline data manipulations on AWS native services [En]'}
        ]
    },
    {
        time : {
            start : '15:05',
            end : '15:15'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Перерыв'}
        ]
    },
    {
        time : {
            start : '15:15',
            end : '15:55'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Игорь Коротач', title : 'State of PySpark, tips and possible improvements [En]'}
        ]
    },
    {
        time : {
            start : '15:55',
            end : '16:05'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Перерыв'}
        ]
    },
    {
        time : {
            start : '16:05',
            end : '16:50'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Павел Галушко', title : 'Как писать для машинного обучения [Ru]'}
        ]
    },
    {
        time : {
            start : '16:50',
            end : '17:05'
        },
        icon : 'coffee', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Кофе-брейк'}
        ]
    },
    {
        time : {
            start : '17:05',
            end : '17:50'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Андрей Латыш', title : 'Ваша модель натренована? Звідси ми підхопимо! [Ua]'}
        ]
    },
    {
        time : {
            start : '17:50',
            end : '18:00'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Перерыв'}
        ]
    },
    {
        time : {
            start : '18:00',
            end : '18:25'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Anton Caceres', title : 'Top Workflow Frameworks for Python Components [En]'}
        ]
    },
    {
        time : {
            start : '18:25',
            end : '18:35'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Перерыв'}
        ]
    },
    {
        time : {
            start : '18:35',
            end : '19:10'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Nele Lea Uhlemann', title : 'Tales of automation - orchestration of Services, Humans and Robots [En]'}
        ]
    },
    {
        time : {
            start : '19:10',
            end : '19:40'
        },
        icon : 'flag', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Закрытие конференции'}
        ]
    },
    {
        time : {
            start : '19:45',
            end : '20:45*'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #1 в Одессу</a>'}
        ]
    },
    {
        time : {
            start : '19:45',
            end : '21:30'
        },
        icon : 'glass', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'After-party'}
        ]
    },
    {
        time : {
            start : '21:30',
            end : '22:30*'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #2 в Одессу</a>'}
        ]
    },
    {
        time : {
            start : '21:30',
            end : '00:00'
        },
        icon : 'glass', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Вечер кино'}
        ]
    },
    {
        time : {
            start : '00:00',
            end : '01:00*'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #3 в Одессу</a>'}
        ]
    },
];
