export const MainSchedule2020Day2Ru = [
    {
        time : {
            start : '09:00',
            end : ''
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #1 на локацию</a>'}
        ]
    },
    {
        time : {
            start : '09:15',
            end : ''
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #2 на локацию</a>'}
        ]
    },
    {
        time : {
            start : '09:30',
            end : ''
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #3 на локацию</a>'}
        ]
    },
    {
        time : {
            start : '10:00',
            end : '10:30'
        },
        icon : 'coffee', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Йога'}
        ]
    },
    {
        time : {
            start : '10:00',
            end : '11:00'
        },
        icon : 'coffee', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Кофе и круассаны'}
        ]
    },
    {
        time : {
            start : '11:00',
            end : '11:45'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Nicolas Fränkel', title : 'A Walkthrough of Python Apps Caching Patterns [En]'}
        ]
    },
    {
        time : {
            start : '11:45',
            end : '12:15'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Перерыв'}
        ]
    },
    {
        time : {
            start : '12:15',
            end : '13:00'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Сергей Коломоец', title : 'CV, livestats, action! [Ru]'}
        ]
    },
    {
        time : {
            start : '13:00',
            end : '13:40'
        },
        icon : 'cutlery', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Обед (часть I)'}
        ]
    },
    {
        time : {
            start : '13:40',
            end : '14:20'
        },
        icon : 'cutlery', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Обед (часть II)'}
        ]
    },
    {
        time : {
            start : '14:20',
            end : '15:00'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Eleftheria Batsou', title : 'It’s Easy To Create A Good-Looking Product, But How About A Useful One? [En]'}
        ]
    },
    {
        time : {
            start : '15:00',
            end : '15:30'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Перерыв'}
        ]
    },
    {
        time : {
            start : '15:30',
            end : '16:00'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Лариса Глоба', title : 'Как Python помогает университетам идти в ногу со временем [Ru]'}
        ]
    },
    {
        time : {
            start : '16:00',
            end : '18:00'
        },
        icon : 'users', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Кофе-брейк/Свободное общение'}
        ]
    },
    {
        time : {
            start : '16:30',
            end : '17:30'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Йога Воркшоп'}
        ]
    },
    {
        time : {
            start : '16:30',
            end : '17:30*'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #1 в Одессу</a>'}
        ]
    },
    {
        time : {
            start : '18:00',
            end : '19:00*'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобусы #2 и #3 в Одессу</a>'}
        ]
    },
];
