export const MainSchedule2020En = [
    {
        time : {
            start : '07:30',
            end : ''
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Bus #1 to the location</a>'}
        ]
    },
    {
        time : {
            start : '08:00',
            end : ''
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Bus #2 to the location</a>'}
        ]
    },
    {
        time : {
            start : '08:30',
            end : ''
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Bus #3 to the location</a>'}
        ]
    },
    {
        time : {
            start : '08:30',
            end : '10:00'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Registration/coffee and croissants'}
        ]
    },
    {
        time : {
            start : '10:00',
            end : '10:25'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Laysa Uchoa', title : '31 days of Python 3.10 [En]'}
        ]
    },
    {
        time : {
            start : '10:25',
            end : '10:35'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Break'}
        ]
    },
    {
        time : {
            start : '10:35',
            end : '11:20'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Vsevolod Solovyov', title : 'My biggest architectural mistake [Ua]'}
        ]
    },
    {
        time : {
            start : '11:20',
            end : '11:30'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Break'}
        ]
    },
    {
        time : {
            start : '11:30',
            end : '12:15'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Andrii Soldatenko', title : 'Debugging asynchronous programs in Python [Ru]'}
        ]
    },
    {
        time : {
            start : '12:15',
            end : '12:25'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Break'}
        ]
    },
    {
        time : {
            start : '12:25',
            end : '13:10'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Secret speaker', title : ''}
        ]
    },
    {
        time : {
            start : '13:10',
            end : '13:50'
        },
        icon : 'cutlery', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Lunch (part I)'}
        ]
    },
    {
        time : {
            start : '13:50',
            end : '14:30'
        },
        icon : 'cutlery', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Lunch (part II)'}
        ]
    },
    {
        time : {
            start : '14:30',
            end : '15:05'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Anton Kartashov', title : 'Streamline data manipulations on AWS native services [En]'}
        ]
    },
    {
        time : {
            start : '15:05',
            end : '15:15'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Break'}
        ]
    },
    {
        time : {
            start : '15:15',
            end : '15:55'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Igor Korotach', title : 'State of PySpark, tips and possible improvements [En]'}
        ]
    },
    {
        time : {
            start : '15:55',
            end : '16:05'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Break'}
        ]
    },
    {
        time : {
            start : '16:05',
            end : '16:50'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Pavel Galushko', title : 'How to write for machine learning [Ru]'}
        ]
    },
    {
        time : {
            start : '16:50',
            end : '17:05'
        },
        icon : 'coffee', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Coffee-break'}
        ]
    },
    {
        time : {
            start : '17:05',
            end : '17:50'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Andrii Latysh', title : 'Is your model trained? We’ll take it from here! [Ua]'}
        ]
    },
    {
        time : {
            start : '17:50',
            end : '18:00'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Break'}
        ]
    },
    {
        time : {
            start : '18:00',
            end : '18:25'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Anton Caceres', title : 'Top Workflow Frameworks for Python Components [En]'}
        ]
    },
    {
        time : {
            start : '18:25',
            end : '18:35'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Break'}
        ]
    },
    {
        time : {
            start : '18:35',
            end : '19:10'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Nele Lea Uhlemann', title : 'Tales of automation - orchestration of Services, Humans and Robots [En]'}
        ]
    },
    {
        time : {
            start : '19:10',
            end : '19:40'
        },
        icon : 'flag', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Closing of the conference'}
        ]
    },
    {
        time : {
            start : '19:45',
            end : '20:45*'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Bus #1 to Odessa</a>'}
        ]
    },
    {
        time : {
            start : '19:45',
            end : '21:30'
        },
        icon : 'glass', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'After-party'}
        ]
    },
    {
        time : {
            start : '21:30',
            end : '22:30*'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Bus #2 to Odessa</a>'}
        ]
    },
    {
        time : {
            start : '21:30',
            end : '00:00'
        },
        icon : 'glass', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Movie evening'}
        ]
    },
    {
        time : {
            start : '00:00',
            end : '01:00*'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Bus #3 to Odessa</a>'}
        ]
    },
];
