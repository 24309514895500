export const Meetup2SpeakersEn = [
    {
      image : '../../public/img/cont/speakers/taras_matsyk.jpg',
      name : 'Тарас Мацик',
      position : 'Senior Go/Python Engineer',
      company : 'spider.com',
      rept : [
        {
          title: 'Remote work: A Fistful of Dollars [Ua]',
          description :
            '<ul>' +
            '<li>1. Що таке віддалена робота, плюси і мінуси</li>'+
            '<li>2. Де її шукати і як</li>'+
            '<li>3. Скільки платять і за що</li>'+
            '<li>4. На-я?</li>'+
            '</ul>'
        }
      ],
    },

    {
      image : '../../public/img/cont/speakers/mikhail_faraponov.jpeg',
      name : 'Михаил Фарапонов',
      position : 'Go Developer ',
      company : 'Beetroot',
      rept : [
        {
          title: 'Миграция highload приложения с Python на Go. История оптимизации веб-паука с 5к до 50к запросов в секунду [Ru]',
          description :
            '<p> В этом докладе я расскажу про разнообразные архитектуры веб-пауков, с точки зрения эффективности' +
            ' использования всех ресурсов машины: CPU, дисковый IO, сеть - их узкие места, а также отличия параллельного' +
            ' и конкурентного программирования в Python и Golang. Доклад зайдет и новичкам и гуру-программистам</p> '
        }
      ]
    },
    {
      image : '../../public/img/cont/speakers/pavlo-galushko.jpeg',
      name : 'Паша Галушко',
      position : 'AIOps, Senior Software Developer',
      company : 'Namecheap. Inc',
      rept : [
        {
          title: ' <b>Мастер-класс [Продолжение]</b>: Паттерны GoF в Python. Антипаттерны [Ru]',
          description :
            '<p>Мир паттернов большой и очень разнообразен. И даже, если ограничиться только книгой GoF, то одной, даже' +
            ' двухчасовой экскурсии, будет мало. Поэтому мы продолжим путешествие. В этот раз в программе будут ' +
            'поведенческие паттерны. Мы разберемся когда их стоит использовать и когда, привет Локи, их лучше избегать.' +
            ' Также я дам пример из реальной жизни для каждого когда его применение вполне оправдано. \n' +
            'Во второй части мы Локи любезно приглашает нас в гости и покажет то, что так знакомо и так больно - ' +
            'анти паттерны. Мы их разберём столько, сколько успеем. К сожалению, разобрать из все - человеческой жизни ' +
            'не хватит. Да и Локи не уверен, что хватит его жизни, а она у него очень длинная.</p>'
        }
      ]
    },

  
    
  ];
