export const MainSpeakers2020En = [

    {
        image : '/public/img/cont/speakers/vsevolod_solovyov.jpg',
        name : 'Vsevolod Solovyov',
        position : 'Co-founder and CTO',
        company : 'Prophy Science',
        place : 'Kyiv, Ukraine',
        rept : [
            {
                title : 'My biggest architectural mistake [Ua]',
                description : '<p>A lot of architecture decisions сan only be evaluated in the long run. ' +
                    'In contrast to my previous projects, I work on the current project since the beginning and for 5 years already. ' +
                    'I’ll talk about how the project made through a 100x increase of data volume, and what was the most painful error.</p>'
            }
        ],
        aboutSpeaker : '',
        socialsRendered : '',
        socials : [
            {
                link : 'https://twitter.com/murkt',
                fatype : 'twitter'
            },
            {
                link : 'https://www.linkedin.com/in/vsolovyov/',
                fatype : 'linkedin'
            },
            {
                link : 'https://www.facebook.com/vsevolod.solovyov',
                fatype : 'facebook'
            }
        ]
    },
    // {
    //     image : '/public/img/cont/speakers/emanuil_tolev.jpg',
    //     name : 'Emanuil Tolev',
    //     position : 'Community Engineer',
    //     company : 'Elastic',
    //     place : 'London, UK',
    //     rept : [
    //         {
    //             title : 'The gentle touch of APM - how code tracing works in Python [En]',
    //             description : '<p>It has been a busy several years in monitoring and observability. ' +
    //                 'As we’ve hit limits on the visibility and detail that logging and metrics provide, ' +
    //                 'we’ve turned to tracing and APM (App Performance Monitoring) systems. ' +
    //                 'We can now understand performance bottlenecks and see errors in our apps down to the line of code. ' +
    //                 'But how do they really work under the hood? Come and find out! ' +
    //                 'We\'ll walk through how a free APM system works - Elastic APM.</p>' +
    //                 '<p>- an overview of typical Python web framework structure<br>' +
    //                 '- Elastic APM\'s tech architecture<br>' +
    //                 '- how its Python agent hooks deep into web apps and Celery back-ends<br>' +
    //                 '- how Python trace data is processed and visualised<br>' +
    //                 '- how it avoids hitting production web app performance while tracing</p>' +
    //                 '<p>This talk is friendly to a variety of backgrounds and levels of experience.' +
    //                 ' It would help a lot if you have worked on a production Python web app,' +
    //                 ' but the focus is on giving an introduction to how these things work.</p>'
    //         }
    //     ],
    //     aboutSpeaker : '',
    //     socialsRendered : '',
    //     socials : [
    //         {
    //             link : 'https://twitter.com/emanuil_tolev/',
    //             fatype : 'twitter'
    //         },
    //         {
    //             link : 'https://github.com/emanuil-tolev/',
    //             fatype : 'github'
    //         },
    //         {
    //             link : 'https://www.linkedin.com/in/emanuil-tolev/',
    //             fatype : 'linkedin'
    //         }
    //     ]
    // },
    // {
    //     image : '/public/img/cont/speakers/amit_kumar.jpg',
    //     name : 'Amit Kumar',
    //     position : 'Software Engineer',
    //     company : 'Aktech Labs',
    //     place : 'London, UK',
    //     rept : [
    //         {
    //             title : 'Build and maintain cost-effective and scalable compute/data science platforms with QHub [En]',
    //             description :
    //                 '<p>I would like to talk about QHub. QHub is an open source tool that enables users to build and ' +
    //                 'maintain cost-effective and scalable compute/data science platforms on ' +
    //                 'HPC or on Kubernetes with minimal DevOps experience.</p>'
    //         }
    //     ],
    //     aboutSpeaker : 'Amit is a presenter and a Software Engineer based in London, UK. He is an avid supporter of Open Source Software.',
    //     socialsRendered : '',
    //     socials : [
    //         {
    //             link : 'http://twitter.com/iaktech',
    //             fatype : 'twitter'
    //         },
    //         {
    //             link : 'http://github.com/aktech',
    //             fatype : 'github'
    //         },
    //         {
    //             link : 'http://iamit.in',
    //             fatype : 'link'
    //         },
    //
    //     ]
    // },
    {
        image : '/public/img/cont/speakers/pavlo-galushko.jpeg',
        name : 'Pavel Galushko',
        position : 'AIOps, Senior Software Developer',
        company : 'Namecheap. Inc',
        place : 'Kharkiv, Ukraine',
        rept : [
            {
                title : 'How to write for machine learning [Ru] Online',
                description :
                    '<p>I\'ll talk about machine learning in general, ' +
                    'talk about what development for machine learning does, ' +
                    'what you are need to know for this, and what technologies and processes we use.</p>'
            }
        ],
        aboutSpeaker : '',
        socialsRendered : '',
        socials : [
            {
                link : "https://www.facebook.com/paul.galushko",
                fatype : "facebook"
            },
            {
                link : "https://www.linkedin.com/in/galushkopavlo/",
                fatype : "linkedin"
            }
        ]
    },
    // {
    //     image : '/public/img/cont/speakers/alex_figura.jpg',
    //     name : 'Alexandre Figura',
    //     position : 'Site Reliability Engineer',
    //     company : 'HelloFresh',
    //     place : 'Berlin, Germany',
    //     rept : [
    //         {
    //             title : 'Instrumenting your Code like Mozart [En]',
    //             description :
    //                 '<p>In a world full of micro-services, with thousands and thousands of internal requests per second, ' +
    //                 'logs are not enough anymore to help us understanding what\'s going on... ' +
    //                 'But what to use then, and in which situation? There are so many tools out there, that it can ' +
    //                 'become quite challenging to answer this question. Fortunately, after this talk, the world of ' +
    //                 'micro-services monitoring will not have any secrets for you, and you will be ready to write your ' +
    //                 'first symphony in Python.</p>' +
    //                 '<p>I will speak about how to increase "observability" of an application. Things like tracing (with Jaeger), ' +
    //                 'logging (with Graylog), metrics (with Prometheus), stacktraces (with Sentry), OpenTelemetry, ' +
    //                 'and how to use them with Python.</p>'
    //         }
    //     ],
    //     aboutSpeaker : 'Alexandre is a french Python developer, living since 4 years in Berlin. ' +
    //         'In his short career, he already worked with more than 20 different nationalities. ' +
    //         'Also, over the years, he gained experience in Web Programming and System/Network Engineering. ' +
    //         'But his favorite topics are testing and code maintenance: because nothing is more important than writing ' +
    //         'sustainable code, to make our planet great again!',
    //     socialsRendered : '',
    //     socials : [
    //         {
    //             link : "https://github.com/arugifa",
    //             fatype : "github"
    //         },
    //         {
    //             link : "https://www.linkedin.com/in/arugifa/",
    //             fatype : "linkedin"
    //         }
    //     ]
    // },
    {
        image : '/public/img/cont/speakers/ihor-korotach.jpg',
        name : 'Igor Korotach',
        position : 'Software Engineer',
        company : 'Quantum',
        place : 'Kharkiv, Ukraine',
        rept : [
            {
                title : 'State of PySpark, tips and possible improvements [En]',
                description :
                    '<p>What is Spark? Why should one use it? PySpark and its internals, integrating Spark ' +
                    'into Machine Learning pipelines using PyTorch and Tensorflow with maximum efficiency. ' +
                    'What is the future of Spark in Python based ML projects.</p>'
            }
        ],
        aboutSpeaker : 'Studying chemistry & computer science. Have previously used multiple programming languages ' +
            'including Go, Rust, Python, Java, C. In the free time I enjoy playing an electric guitar and billiards.',
        socialsRendered : '',
        socials : [
            {
                link : "https://twitter.com/Emulebest",
                fatype : "twitter"
            },
            {
                link : "https://github.com/Emulebest",
                fatype : "github"
            },
            {
                link : "https://www.linkedin.com/in/igor-korotach-806435154/",
                fatype : "linkedin"
            }
        ]
    },
    {
        image : '/public/img/cont/speakers/anton_caceres.jpg',
        name : 'Anton Caceres',
        position : 'CEO',
        company : 'TECH-5',
        place : 'Munich, Germany',
        rept : [
            {
                title : 'Top Workflow Frameworks for Python Components [En]',
                description : '<p>Business is all about processes. To automate them, workflow frameworks can be integrated. ' +
                    'They orchestrate underlying software components and visualize the flow. Modern frameworks can manage ' +
                    'failures and retries, parallelization and service integration, while providing observability so that ' +
                    'developers can focus on business logic.</p>' +
                    '<p>In this talk, we will go over top workflow frameworks for Python developers - Airflow, Step Functions, and GCP Workflows.</p>'
            }
        ],
        aboutSpeaker : '',
        socialsRendered : '',
        socials : [
            {
                link : 'http://github.com/ma3str0',
                fatype : 'github'
            },
            {
                link : 'https://twitter.com/anton_caceres',
                fatype : 'twitter'
            },
            {
                link : 'https://caceres.me',
                fatype : 'link'
            }
        ]
    },
    {
        image : '/public/img/cont/speakers/laysa_uchoa.jpg',
        name : 'Laysa Uchoa',
        position : 'Software Engineer',
        company : 'The Mobility House',
        place : 'Munich, Germany',
        rept : [
            {
                title : '31 days of Python 3.10 [En]',
                description : '<p>Do you feel stuck in an older version of Python? Are you curious about what is out there with Python 3.10? In this talk, we will go over an exciting journey of using Python 3.10 for 31 days. ' +
                    'Some new things include pattern matching, better error messages, union operators, and more.</p>'
            }
        ],
        aboutSpeaker : '',
        socialsRendered : '',
        socials : [
            {
                link : 'https://github.com/laysauchoa',
                fatype : 'github'
            },
            {
                link : 'https://twitter.com/laysauchoa',
                fatype : 'twitter'
            },
            {
                link : 'https://www.linkedin.com/in/laysauchoa/',
                fatype : 'linkedin'
            }
        ]
    },
    {
        image : '/public/img/cont/speakers/andrii-latysh.jpeg',
        name : 'Andrii Latysh',
        position : 'Technical Product Owner',
        company : 'Provectus',
        place : 'Odessa, Ukraine',
        rept : [
            {
                title : 'Your model is trained? We’ll take it from here! [Ua]',
                description : '<p>Setting up a solution with machine learning in production is often a difficult ' +
                    'and problematic task. This talk will cover what you need to turn your model into a full-fledged ' +
                    'solution, as well as introduce you to Hydrosphere - an open-source platform for promoting and managing your solution in production.</p>'
            }
        ],
        aboutSpeaker : '',
        socialsRendered : '',
        socials : [
            {
                link : 'https://www.facebook.com/andrii.latysh/',
                fatype : 'facebook'
            },
            {
                link : 'https://www.linkedin.com/in/andrii-latysh-999302114/',
                fatype : 'linkedin'
            }
        ]
    },
    {
        image : '/public/img/cont/speakers/andrii-soldatenko.jpg',
        name : 'Andrii Soldatenko',
        position : 'Principal Software Engineer',
        company : 'Solarisbank AG',
        place : 'Kyiv, Ukraine',
        rept : [
            {
                title : 'Debugging asynchronous programs in Python [Ru]',
                description : '<p>Recently the interest in asynchronous programming has grown dramatically. ' +
                    'Unfortunately, asynchronous programs do not always have reproducible behavior. Even when they are run with the same inputs, their results can be radically different. ' +
                    ' In this talk I\'ll show you different approaches on how to debug asynchronous programs in Python.</p>'
            }
        ],
        aboutSpeaker : '',
        socialsRendered : '',
        socials : [
            {
                link : 'https://twitter.com/a_soldatenko',
                fatype : 'twitter'
            },
            {
                link : 'https://github.com/andriisoldatenko/',
                fatype : 'github'
            },
            {
                link : 'https://www.linkedin.com/in/andriisoldatenko/',
                fatype : 'linkedin'
            }
        ]
    },
    {
        image : '/public/img/cont/speakers/nf.jpg',
        name : 'Nicolas Fränkel',
        position : 'Developer Advocate',
        company : 'Hazelcast',
        place : 'Saint Julien en Genevois, France',
        rept : [
            {
                title : 'A Walkthrough of Python Apps Caching Patterns [En]',
                description : '<p>You might have been using cache for years, and still always design the application to be responsible for the sync between the cache and the underlying data store. Did you know there are many more options available?</p>'+
                    '<p>When your application starts slowing down, the reason is probably a bottleneck somewhere in the execution chain. Sometimes, this bottleneck is due to a bug. Sometimes, somebody didn’t set up the optimal configuration. And sometimes, the process of fetching the data is the bottleneck.</p>'+
                    '<p>One option would be to change your whole architecture. Before moving to such a drastic, and probably expensive measure, one can consider a trade-off: instead of getting remote data every time, you can store the data locally after the first read. This is the trade-off that caching offers: stale data vs. speed.</p>'+
                    '<p>Deciding to use caching is just the first step in a long journey. The next step is to think about how your application and the cache will interact. This talk focuses on options available regarding those interactions.</p>'
            }
        ],
        aboutSpeaker : '',
        socialsRendered : '',
        socials : [
            {
                link : 'https://twitter.com/nicolas_frankel',
                fatype : 'twitter'
            },
            {
                link : 'https://github.com/nfrankel',
                fatype : 'github'
            },
            {
                link : 'https://blog.frankel.ch/',
                fatype : 'link'
            }
        ]
    },
    {
        image : '/public/img/cont/speakers/lg.jpeg',
        name : 'Larisa Globa',
        position : 'Professor',
        company : 'NTUU KPI',
        place : 'Kyiv, Ukraine',
        rept : [
            {
                title : 'How Python helps Education to keep up with Technology [Ru]',
                description : '<p>It is hard to keep up with the new frameworks, trends and developments even for programmers.' +
                    'And it is even harder to create an education program for the next 6 years, when you know that it will be obsolete in a year.</p>' +
                    '<p>In a modern world Python keeps a role of a modern general-purpose language, that connects different branches of science and industry.' +
                    'Universities follow this example and make Python main, basic language for studying.</p>'
            }
        ],
        aboutSpeaker : '',
        socialsRendered : '',
        socials : [
            {
                link : 'https://www.linkedin.com/in/larysa-globa-50261131',
                fatype : 'linkedin'
            }
        ]
    },
    {
        image : '/public/img/cont/speakers/eb.jpeg',
        name : 'Eleftheria Batsou',
        position : 'Community Manager',
        company : 'Hashnode',
        place : 'Thessaloniki, Greece',
        rept : [
            {
                title : 'It’s Easy To Create A Good-Looking Product, But How About A Useful One? [En]',
                description : '<p>What to expect:</p>' +
                    '<p>UX vs UI: The two different perspectives of UX and UI, the importance of each, ' +
                    'what does a UX designer do, what does a UI designer do, the different categories of UX as well as UI.</p>' +
                    '<p>About Heuristics: What is the heuristic evaluation? How to conduct an effective heuristic evaluation? why do we need it?</p>' +
                    '<p>The 10 Usability Heuristics (by Jakob Nielson and Don Norman): With tips and examples.</p>' +
                    '<p>The Do’s and Don’ts of UX: Quick tips about UX researchers and designers having always in mind the user.</p>' +
                    '<p>The Good and the Bad of UI: Presenting in a fun way how a good designer works VS a bad designer.</p>'
            }
        ],
        aboutSpeaker : '',
        socialsRendered : '',
        socials : [
            {
                link : 'https://www.linkedin.com/in/eleftheriabatsou/',
                fatype : 'linkedin'
            },
            {
                link : 'https://twitter.com/BatsouElef',
                fatype : 'twitter'
            },
            {
                link : 'http://eleftheriabatsou.com/',
                fatype : 'link'
            }
        ]
    },
    {
        image : '/public/img/cont/speakers/ak.jpeg',
        name : 'Anton Kartashov',
        position : 'Cloud Solution Architect',
        company : 'Crayon',
        place : 'Munich, Germany',
        rept : [
            {
                title : 'Streamline data manipulations on AWS native services [En]',
                description : '<p>This talk presents modern Amazon AWS data services and AWS Data Wrangler - an open source ' +
                    'Python initiative, built on top of Pandas, Apache Arrow and Boto3 to facilitate developers and data engineers.</p>'
            }
        ],
        aboutSpeaker : '',
        socialsRendered : '',
        socials : [
            {
                link : 'https://www.linkedin.com/in/anton-kartashov-b0b056121/',
                fatype : 'linkedin'
            }
        ]
    },
    {
        image : '/public/img/cont/speakers/sk.jpg',
        name : 'Serhii Kolomoiets',
        position : 'Solution architect',
        company : 'WePlay Esports',
        place : 'Kyiv, Ukraine',
        rept : [
            {
                title : 'CV, livestats, action! [Ru]',
                description : '<p>How to replace 5 pairs of human eyes with computer vision and get real-time statistics live.</p>'
            }
        ],
        aboutSpeaker : '',
        socialsRendered : '',
        socials : [
            {
                link : 'https://twitter.com/maram4ik',
                fatype : 'twitter'
            },
            {
                link : 'https://www.linkedin.com/in/serkolomoets/',
                fatype : 'linkedin'
            }
        ]
    },
    {
        image : '/public/img/cont/speakers/nu.jpg',
        name : 'Nele Lea Uhlemann',
        position : 'Developer Advocate',
        company : 'Camunda',
        place : 'Berlin, Germany',
        rept : [
            {
                title : 'Tales of automation - orchestration of Services, Humans and Robots [En]',
                description : '<p>Automation is everywhere! The talk is a hand on session of workflow automation with Python and Camunda.</p>' +
                    '<p>It demonstrates the concepts of service orchestration and integrating your favorite Python applications, ' +
                    'the right usage and dose of RPA tooling, and the possibility to involve human users.</p>'
            }
        ],
        aboutSpeaker : '',
        socialsRendered : '',
        socials : [
            {
                link : 'https://twitter.com/nele_lea',
                fatype : 'twitter'
            },
            {
                link : 'https://www.linkedin.com/in/nele-uhlemann-268b68191/',
                fatype : 'linkedin'
            },
            {
                link : 'https://github.com/Nlea',
                fatype : 'github'
            }
        ]
    }
];