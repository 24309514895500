export const MainSchedule2020Day2Ua = [
    {
        time : {
            start : '09:00',
            end : ''
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #1 на локацію</a>'}
        ]
    },
    {
        time : {
            start : '09:15',
            end : ''
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #2 на локацію</a>'}
        ]
    },
    {
        time : {
            start : '09:30',
            end : ''
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #3 на локацію</a>'}
        ]
    },
    {
        time : {
            start : '10:00',
            end : '10:30'
        },
        icon : 'coffee', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Йога'}
        ]
    },
    {
        time : {
            start : '10:00',
            end : '11:00'
        },
        icon : 'coffee', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Кава та круасани'}
        ]
    },
    {
        time : {
            start : '11:00',
            end : '11:45'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Nicolas Fränkel', title : 'A Walkthrough of Python Apps Caching Patterns [En]'}
        ]
    },
    {
        time : {
            start : '11:45',
            end : '12:15'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Перерва'}
        ]
    },
    {
        time : {
            start : '12:15',
            end : '13:00'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Сергій Коломоєць', title : 'CV, livestats, action! [Ru]'}
        ]
    },
    {
        time : {
            start : '13:00',
            end : '13:40'
        },
        icon : 'cutlery', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Обід (частина I)'}
        ]
    },
    {
        time : {
            start : '13:40',
            end : '14:20'
        },
        icon : 'cutlery', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Обід (частина II)'}
        ]
    },
    {
        time : {
            start : '14:20',
            end : '15:00'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Eleftheria Batsou', title : 'It’s Easy To Create A Good-Looking Product, But How About A Useful One? [En]'}
        ]
    },
    {
        time : {
            start : '15:00',
            end : '15:30'
        },
        icon : 'clock-o', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Перерва'}
        ]
    },
    {
        time : {
            start : '15:30',
            end : '16:00'
        },
        icon : 'file-text', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : 'Лариса Глоба', title : 'Як Python допомогає університетам йти в ногу з часом [Ru]'}
        ]
    },
    {
        time : {
            start : '16:00',
            end : '18:00'
        },
        icon : 'users', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Кава-брейк/Вільне спілкування'}
        ]
    },
    {
        time : {
            start : '16:30',
            end : '17:30'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : 'Йога Воркшоп'}
        ]
    },
    {
        time : {
            start : '16:30',
            end : '17:30*'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобус #1 в Одесу</a>'}
        ]
    },
    {
        time : {
            start : '18:00',
            end : '19:00*'
        },
        icon : 'user', // can be: 'user', 'file-text', 'clock-o', 'coffee', 'cutlery', 'flag', 'glass'
        scheduleRendered : '',
        talks : [
            {author : '', title : '<a href="#transfer">Автобуси #2 и #3 в Одесу</a>'}
        ]
    },
];

